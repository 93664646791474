<template>
  <div class="vlt-category-container">
    <div class="container-content">
      <div class="title-div">
        <div>
          <h5 class="content-title">{{ query }}</h5>
        </div>
        <div>
          <img
            class="close"
            src="@/assets/icons/close.svg"
            alt="vlite-logo"
            loading="lazy"
            @click="actClose"
          />
        </div>
      </div>

      <!-- <h5 class="content-title">{{query}}</h5> -->
      <div
        class="vlt-contents vlt-display"
        v-if="!contents"
      >
        <p class="no-data">{{ noData }}</p>
      </div>
    </div>

    <list-contents v-if="contents" :passContents="contents" :contentType="contentType" :categoryTitle="category" :orientation="orientation">
    </list-contents>

    <app-loader
      class="loader col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12"
      v-show="showLoading"
    ></app-loader>
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "SearchList",
  data() {
    return {
      contents: null,
      query: undefined,
      noData: null,
      showLoading: false,
      flagAutologin: true,
      category: "Shows",
      contentType: undefined,
      orientation: 'LANDSCAPE'
    };
  },
  computed: {
    ...mapGetters(["appMenu", "subscriberId"]),
  },
  watch: {
    $route(to, from) {
      this.query = this.$route.params.query;
      if(this.$route.params.result){
        this.contents = this.$route.params.result;
      }
      this.listContents(this.query);
    },
    subscriberId() {
      this.query = this.$route.params.query;
      if(this.$route.params.result){
        this.contents = this.$route.params.result;
      }
      this.listContents(this.query);
      EventBus.$emit("checkWatchButton", true);
    },
  },
  methods: {
    ...mapActions(["actListContents", "actListCategories"]),
    actClose() {
      this.$router.push({ name: "Home" });
    },
    listContents(data) {
      let params = {};
      params.query = data;
      this.showLoading = true;

      this.actListContents(params)
        .then((response) => {
          this.showLoading = false;

          if (response.error && response.error === 2001) {
            this.contents = "";
            this.noData = "No Data";
          }
          if (response && response.length) {
            this.contents = response;
          }

        })
        .catch((error) => {
          this.showLoading = false;

          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.listContents(data));
              this.flagAutologin = false;
              return;
            }
          }
        });
    }
  },
  created() {
    this.query = this.$route.params.query;
    this.contents = this.$route.params.result;
    this.listContents(this.query);
  },
  components: {
    "list-contents": () =>
      import(
        /* webpackChunkName: "listContents" */ "@/components/Shared/templates/listContents.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins],
};
</script>
<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";

.vlt-display {
  text-align: center;
}

.title-div {
  display: flex;
  justify-content: space-between;
  .close {
    cursor: pointer;
    @include closeIconHover;
  }
}

.loader {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vlt-category-container {
  // margin: 3em 0 10em;
  max-width: var(--max-width);
  margin: auto;
    .container-content {
    margin: 48px 0 0 0;
  }
}
.content-title {
  text-align: left;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 1.75rem;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: $font-clr-white;
}
.vlt-height {
  height: 330px;
}
.content-div {
  width: 339px;
  // margin: auto 2%;
}
p {
  padding: 1%;
  text-align: center;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 15px;
}
.vlt-contents {
  margin-bottom: 20px;
  margin-top: 2%;
  padding: 5px;
  &.content-block{
    flex:1;
  }
}
.parent-container{
  display: flex;
  flex-flow: row wrap;
}
.vlt-details {
  width: 100%;
  margin-bottom: 20px;
  margin-top: -15px;
}
.no-data {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-gray;
  font-size: 17px;
  line-height: 32px;
}

.down-arrow {
  position: relative;
  top: -6px;
  height: 15px;
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $theme-primary;
  }
}

@include breakpoint(min980max1280) {
  .content-div {
    width: 260px;
  }
  .vlt-height {
    height: 270px;
  }
}
@include breakpoint(max480){
   .content-div {
    width: 260px;
  }
  .vlt-height {
    height: 270px;
  }
}

@include breakpoint(max1280) {
  .vlt-category-container {
    width: 80vw;
  }
}
</style>